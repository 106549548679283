.ant-table .ant-table-content::-webkit-scrollbar {
  width: initial !important;
  height: initial !important;
}

.ant-table .ant-table-content::-webkit-scrollbar-track {
  background: initial !important;
}

.ant-table .ant-table-content::-webkit-scrollbar-thumb {
  border-radius: initial !important;
  background: initial !important;
}

@media screen and (max-width: 778px) {
  .ant-layout-content {
    background-color: white;
  }
}