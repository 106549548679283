.penaltyCard {
  margin: auto;
  border: 2px solid;
  border-radius: 10px;
  text-align: center;
  height: max-content;
  padding: 20px;
  font-size: 24px;
}

.greenCard {
  border-color: #3dd67b;
  color: #3dd67b;
  background-color: #f5fff9;
}

.redCard {
  border-color: #e35053;
  color: #e35053;
  background-color: #f6cacc;
}