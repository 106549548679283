/* @import '~antd/dist/antd.css'; */

.ant-card-head {
  font-weight: bolder !important;
  font-size: 1.3rem !important;
}


::-webkit-scrollbar {
  width: 9px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
}

.ant-carousel .slick-slide {
  overflow: hidden;
}
.slick-dots li {
  background-color: #b8b9b996;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.ant-carousel .slick-dots li button {
  background: #8a7878 !important;
}