#header-dashboard {
  box-shadow: 0 2px 8px #f0f1f2;
  background-color: #fff;
  z-index: 2;
}

.header-nav {
  display: flex; 
  align-content: center; 
}

.wrapper-items{
  display: flex;
  float: right;
  height: 64px;
  margin-left: auto;
  overflow: hidden;
}


.wrapper-items > *:hover {
  cursor: pointer;
}

.custom-spinner {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.menu-sidebar{
  overflow: auto; 
  height: calc(100vh - 190px);  
}

.barra-horizontal {
  display: flex;
  align-content: stretch;
  width: 500px;
  height: 64px;
}

.barra-horizontal img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.siderbar-alcaldia {
  display: flex; 
  height: 80px;  
  justify-content: center;
}

.logo {
  margin: 0;
  display: flex;
  align-content: center;
  color: white;
}